.font-swash {
  font-family: 'Kaushan Script', Calibri, cursive; }

.font-sans {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif; }

.btn-fp-form {
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  border-radius: 0; }

.hero {
  position: relative;
  background-image: url(/images/home/wave-ext.svg), url(/images/home/homepage-wave-bg.jpg);
  background-position: 50% 101%, 50% 0;
  background-size: 0, cover;
  background-repeat: no-repeat, no-repeat;
  background-color: #fff;
  padding-top: 1.5em;
  padding-bottom: 3.5em; }
  .hero .font-sans {
    font-size: calc(1em + 2vw);
    line-height: 1; }
  .hero .font-swash {
    font-size: calc(2.5em + 2vw);
    line-height: 1; }

.hero h1 {
  color: #fff; }

.hp-tagline {
  color: #1d586a;
  font-size: calc(1em + 2vw);
  margin: 0 0 0.75em 0;
  letter-spacing: 0.03em; }

.hp-cta-body {
  font-size: 2em;
  color: #1d586a;
  line-height: 1.2;
  margin: 0.5em 0 1.5em 0; }
  @media screen and (max-width: 768px) {
    .hp-cta-body {
      font-size: 1.1em; } }

.hp-cta-body em {
  font-weight: bold;
  color: #222; }

.get-started-btn {
  border: 3px solid #faef81;
  border-radius: 10px;
  letter-spacing: 0.125em;
  display: inline-block;
  padding: 0.5em 1.25em;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: calc(0.85em + 1.5vw); }
  .get-started-btn:hover, .get-started-btn:active, .get-started-btn:focus {
    color: #184756;
    text-decoration: none; }

.hero-picture {
  max-width: 100%; }

.hero-wave {
  position: absolute;
  bottom: 0;
  z-index: 1;
  fill: #fff;
  left: 0;
  display: block; }

.hp-tiles {
  margin: 3em 0; }

.fp2017_group input.form-control {
  border-radius: 0; }

@media screen and (max-width: 575px) {
  .hp-tiles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .hp-tile {
    max-width: 290px;
    max-height: 290px;
    margin: auto; } }

.hp-tile {
  position: relative; }
  .hp-tile.hp-tile--a .hp-tile-title {
    background-color: #4e82ca; }
  .hp-tile.hp-tile--b .hp-tile-title {
    background-color: #01496a; }
  .hp-tile.hp-tile--c .hp-tile-title {
    background-color: #6698b2; }

.hp-tile-img {
  -webkit-filter: sepia(0.65) hue-rotate(-25deg) saturate(0.9) brightness(0.8);
  filter: sepia(0.65) hue-rotate(-25deg) saturate(0.9) brightness(0.8); }

.hp-tile-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5em;
  background-color: #0d9ebf;
  color: #fff;
  margin: 0;
  line-height: 1.3;
  font-size: 1.6em;
  font-family: 'PT Sans Narrow'; }

.lower-cta {
  background-color: #0d9ebf;
  color: #fff; }

.lower-cta h2 {
  color: #fff;
  font-size: calc(1em + 1.5vw); }

.lower-cta a h2 {
  color: #fff;
  font-size: 2em;
  margin-bottom: 0; }

.lower-cta a p {
  line-height: 1.3; }

.hp-everyone {
  margin: 1em 0 2em; }

.hp-everyone a {
  min-height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 5px solid #fff;
  text-align: center; }
  .hp-everyone a:hover, .hp-everyone a:active {
    text-decoration: none;
    background-color: #0fb2d7; }

.hp-everyone a > h2 {
  color: #1d586a; }

.hp-everyone a > p {
  font-size: 1.3em;
  color: #444; }

.vendor-logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media screen and (max-width: 993px) {
    .vendor-logos {
      width: 40em;
      margin: auto; } }
  @media screen and (max-width: 575px) {
    .vendor-logos {
      width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column; } }

.vendor-logo-link {
  padding: 0.75em; }

.vendor-logos .vervante-logo {
  height: 30px;
  width: 125px; }

.vendor-logo--amazon {
  -webkit-transform: translateY(3px);
  transform: translateY(3px); }

.vendor-logo--bn {
  height: 25px;
  width: 140px;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px); }

.vendor-logo--baker {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px); }

.vendor-logo--kindle {
  -webkit-transform: translateY(4px);
  transform: translateY(4px); }

.btn-fp-form,
a.btn--home {
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  border-radius: 0;
  font-weight: normal; }

a.btn--home {
  font-size: 1em;
  color: #1d586a;
  border: 3px solid #bed3cc; }

.hero-text .font-swash {
  font-size: calc(1.5em + 2vw);
  line-height: 1.3; }
  @media screen and (min-width: 375px) {
    .hero-text .font-swash {
      font-size: calc(1.75em + 2vw); } }

.hero__line-1,
.hero__line-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline; }

.hero-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0; }

.hp-tile {
  margin: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block; }

/* autoprefixer grid: no-autoplace */
.hero-text {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[12];
  grid-template-columns: repeat(12, 1fr);
  -ms-grid-rows: 20px (auto)[2] 20px;
  grid-template-rows: 20px repeat(2, auto) 20px;
  margin: 0 auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3), 2px 2px 5px rgba(0, 0, 0, 0.2);
  max-width: unset; }

.hero__line-1 {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
  -ms-grid-row: 2;
  grid-row: 2; }

.hero__line-2 {
  -ms-grid-column: 3;
  -ms-grid-column-span: 10;
  grid-column: 3/13;
  -ms-grid-row: 3;
  grid-row: 3; }

@media screen and (min-width: 425px) {
  .hero-text .font-swash {
    font-size: calc(2.5em + 2vw); } }

@media screen and (min-width: 993px) {
  .hero-text .font-swash {
    font-size: calc(2.75em + 2vw); }
  .hero__line-1 {
    -ms-grid-column: 1;
    -ms-grid-column-span: 11;
    grid-column: 1/12;
    -ms-grid-row: 2;
    grid-row: 2; }
  .hero__line-2 {
    -ms-grid-column: 5;
    -ms-grid-column-span: 8;
    grid-column: 5/13;
    -ms-grid-row: 3;
    grid-row: 3; } }

@supports (display: grid) {
  .hero {
    background-size: 100vw, cover; } }

.hp-tiles {
  padding: 0 15px; }

@media screen and (min-width: 576px) {
  /* autoprefixer grid: autoplace */
  .hp-tiles {
    display: -ms-grid;
    display: grid;
    grid-gap: 10px;
    -ms-grid-columns: minmax(200px, 1fr) 10px minmax(200px, 1fr);
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    -ms-grid-rows: minmax(200px, 1fr) 10px minmax(200px, 1fr) 10px minmax(200px, 1fr) 10px minmax(200px, 1fr) 10px minmax(200px, 1fr) 10px minmax(200px, 1fr);
    grid-template-rows: repeat(6, minmax(200px, 1fr)); }
  .hp-tiles > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(5) {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(6) {
    -ms-grid-row: 5;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(7) {
    -ms-grid-row: 7;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(8) {
    -ms-grid-row: 7;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(9) {
    -ms-grid-row: 9;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(10) {
    -ms-grid-row: 9;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(11) {
    -ms-grid-row: 11;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(12) {
    -ms-grid-row: 11;
    -ms-grid-column: 3; } }

@media screen and (min-width: 993px) {
  /* autoprefixer grid: autoplace */
  .hp-tiles {
    -ms-grid-columns: minmax(200px, 1fr) 15px minmax(200px, 1fr) 15px minmax(200px, 1fr);
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    -ms-grid-rows: minmax(200px, 1fr) 15px minmax(200px, 1fr) 15px minmax(200px, 1fr) 15px minmax(200px, 1fr);
    grid-template-rows: repeat(4, minmax(200px, 1fr));
    grid-gap: 15px; }
  .hp-tiles > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5; }
  .hp-tiles > *:nth-child(4) {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 5; }
  .hp-tiles > *:nth-child(7) {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(8) {
    -ms-grid-row: 5;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 5; }
  .hp-tiles > *:nth-child(10) {
    -ms-grid-row: 7;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(11) {
    -ms-grid-row: 7;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(12) {
    -ms-grid-row: 7;
    -ms-grid-column: 5; } }

@media screen and (min-width: 1200px) {
  /* autoprefixer grid: autoplace */
  .hp-tiles {
    -ms-grid-columns: minmax(200px, 1fr) 15px minmax(200px, 1fr) 15px minmax(200px, 1fr) 15px minmax(200px, 1fr);
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    -ms-grid-rows: minmax(200px, 1fr) 15px minmax(200px, 1fr) 15px minmax(200px, 1fr);
    grid-template-rows: repeat(3, minmax(200px, 1fr)); }
  .hp-tiles > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 5; }
  .hp-tiles > *:nth-child(4) {
    -ms-grid-row: 1;
    -ms-grid-column: 7; }
  .hp-tiles > *:nth-child(5) {
    -ms-grid-row: 3;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(6) {
    -ms-grid-row: 3;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(7) {
    -ms-grid-row: 3;
    -ms-grid-column: 5; }
  .hp-tiles > *:nth-child(8) {
    -ms-grid-row: 3;
    -ms-grid-column: 7; }
  .hp-tiles > *:nth-child(9) {
    -ms-grid-row: 5;
    -ms-grid-column: 1; }
  .hp-tiles > *:nth-child(10) {
    -ms-grid-row: 5;
    -ms-grid-column: 3; }
  .hp-tiles > *:nth-child(11) {
    -ms-grid-row: 5;
    -ms-grid-column: 5; }
  .hp-tiles > *:nth-child(12) {
    -ms-grid-row: 5;
    -ms-grid-column: 7; } }

.hp-tile {
  background: #f7f7f7; }

.homepage-separator {
  margin-top: 15px;
  margin-bottom: 30px;
  height: 3px;
  background: -webkit-gradient(linear, left top, right top, from(white), color-stop(#22536d), to(white));
  background: linear-gradient(to right, white, #22536d, white); }

.homepage-blue-bar {
  margin: 30px 0;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 1.4em;
  background: #116eb7;
  letter-spacing: 0.07em;
  padding: 8px; }
  .homepage-blue-bar a {
    color: white; }
    .homepage-blue-bar a:hover, .homepage-blue-bar a:focus, .homepage-blue-bar a:active {
      text-decoration: none;
      background-color: #0fb2d7; }

div.homepage-box {
  display: -ms-grid;
  display: grid;
  grid-gap: 0px;
  grid-column-gap: 0px;
  -ms-grid-columns: 50% 50%;
  grid-template-columns: 50% 50%;
  margin: 30px auto 20px auto;
  max-width: 900px; }
  @media screen and (max-width: 650px) {
    div.homepage-box {
      -ms-grid-columns: 100%;
      grid-template-columns: 100%; } }

div.homepage-box-text-a {
  background-color: #02334b; }

div.homepage-box-text-b {
  background-color: #355868; }

div.homepage-box-text-c {
  background-color: #4d7584; }

div.homepage-box-text-d {
  background-color: #73a5ad; }

.homepage-box-text h3 {
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  font-size: 2em;
  margin-top: 35px;
  color: #faef81; }

.homepage-box-text p {
  color: #f7f7f7;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  font-size: 1.5em;
  line-height: 1.3;
  margin: 30px; }

.homepage-tell-me-more-container {
  margin: 25px; }

.homepage-tell-me-more a {
  background-color: white;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  color: #01496a;
  font-weight: bold;
  border-radius: 2px;
  padding: 0 9px;
  -webkit-box-shadow: 0 0 0 5px white, 0 0 0 6px black, 0 0 0 10px white;
  box-shadow: 0 0 0 5px white, 0 0 0 6px black, 0 0 0 10px white; }
  .homepage-tell-me-more a:hover, .homepage-tell-me-more a:focus, .homepage-tell-me-more a:active {
    text-decoration: none;
    color: #013751; }

.homepage-box-text {
  heigth: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
