@import "variables";

.font-swash {
  font-family: 'Kaushan Script', Calibri, cursive;
}

.font-sans {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
}

.btn-fp-form {
  font-family: $font-family-sans;
  border-radius: 0;
}

.hero {
  position: relative;
  background-image: url(/images/home/wave-ext.svg), url(/images/home/homepage-wave-bg.jpg);
  background-position: 50% 101%, 50% 0;
  background-size: 0, cover;
  background-repeat: no-repeat, no-repeat;
  background-color: #fff;
  padding-top: 1.5em;
  padding-bottom: 3.5em;

  .font-sans {
    font-size: calc(1em + 2vw);
    line-height: 1;
  }

  .font-swash {
    font-size: calc(2.5em + 2vw);
    line-height: 1;
  }
}

.hero h1 {
  color: #fff;
}

.hp-tagline {
  color: $v-green;
  font-size: calc(1em + 2vw);
  margin: 0 0 0.75em 0;
  letter-spacing: 0.03em;
}

.hp-cta-body {
  font-size: 2em;
  color: $v-green;
  line-height: 1.2;
  margin: 0.5em 0 1.5em 0;

  @media screen and (max-width: 768px) {
    font-size: 1.1em;
  }
}

.hp-cta-body em {
  font-weight: bold;
  color: #222;
}

.get-started-btn {
    border: 3px solid $v-yellow;
  border-radius: 10px;
  letter-spacing: 0.125em;
  display: inline-block;
  padding: 0.5em 1.25em;
  font-family: $font-family-sans;
  font-weight: bold;
  font-size: calc(0.85em + 1.5vw);

  &:hover,
  &:active,
  &:focus {
    color: darken($v-green, 5%);
    text-decoration: none;
  }
}

.hero-picture {
  max-width: 100%;
}

.hero-wave {
  position: absolute;
  bottom: 0;
  z-index: 1;
  fill: #fff;
  left: 0;
  display: block;
}

.hp-tiles {
  margin: 3em 0;
}

.fp2017_group input.form-control {
  border-radius: 0;
}

@media screen and (max-width: 575px) {
  .hp-tiles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hp-tile {
    max-width: 290px;
    max-height: 290px;
    margin: auto;
  }
}

.hp-tile {
  position: relative;

  &.hp-tile--a .hp-tile-title {
    background-color: $tile-a;
  }

  &.hp-tile--b .hp-tile-title {
    background-color: $tile-b;
  }

  &.hp-tile--c .hp-tile-title {
    background-color: $tile-c;
  }
}


.hp-tile-img {
  filter: sepia(.65) hue-rotate(-25deg) saturate(0.9) brightness(.8);
}

.hp-tile-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5em;
  background-color: $teal;
  color: #fff;
  margin: 0;
  line-height: 1.3;
  font-size: 1.6em;
  font-family: 'PT Sans Narrow';
}

.lower-cta {
  background-color: $teal;
  color: #fff;
}

.lower-cta h2 {
  color: #fff;
  font-size: calc(1em + 1.5vw);
}

.lower-cta a h2 {
  color: #fff;
  font-size: 2em;
  margin-bottom: 0;
}

.lower-cta a p {
  line-height: 1.3;
}

.hp-everyone {
  margin: 1em 0 2em;
}

.hp-everyone a {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px solid #fff;
  text-align: center;

  &:hover,
  &:active {
    text-decoration: none;
    background-color: lighten($teal, 5%);
  }
}

.hp-everyone a > h2 {
  color: $v-green;
}

.hp-everyone a > p {
  font-size: 1.3em;
  color: #444;
}

.vendor-logos {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: 993px) {
    width: 40em;
    margin: auto;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    flex-direction: column;
  }
}

.vendor-logo-link {
  padding: 0.75em;
}

.vendor-logos .vervante-logo {
  height: 30px;
  width: 125px;
}

.vendor-logo--amazon {
  transform: translateY(3px);
}

.vendor-logo--bn {
  height: 25px;
  width: 140px;
  transform: translateY(-3px);
}

.vendor-logo--baker {
  transform: translateY(-3px);
}

.vendor-logo--kindle {
  transform: translateY(4px);
}

.vendor-logo--nook {
}

.btn-fp-form,
a.btn--home {
  font-family: $font-family-sans;
  border-radius: 0;
  font-weight: normal;
}

a.btn--home {
  font-size: 1em;
  color: $v-green;
  border: 3px solid #bed3cc;
}


.hero-text .font-swash {
  font-size: calc(1.5em + 2vw);
  line-height: 1.3;

  @media screen and (min-width: 375px) {
  font-size: calc(1.75em + 2vw);
  }
}

.hero__line-1,
.hero__line-2 {
  display: flex;
  align-items: baseline;
}

.hero-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.hp-tile {
  margin: 10px;
  box-sizing: border-box;
  display: block;
}

/* autoprefixer grid: no-autoplace */
.hero-text {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 20px repeat(2, auto) 20px;
  margin: 0 auto;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.3), 2px 2px 5px rgba(0,0,0,0.2);
  max-width: unset;
}

.hero__line-1 {
  grid-column: 1/13;
  grid-row: 2;
}

.hero__line-2 {
  grid-column: 3/13;
  grid-row: 3;
}


@media screen and (min-width: 425px) {
  .hero-text .font-swash {
    font-size: calc(2.5em + 2vw);
  }
}

@media screen and (min-width: 993px) {
  .hero-text .font-swash {
    font-size: calc(2.75em + 2vw);
  }

  .hero__line-1 {
    grid-column: 1/12;
    grid-row: 2;
  }

  .hero__line-2 {
    grid-column: 5/13;
    grid-row: 3;
  }
}


@supports (display: grid) {
  .hero {
    background-size: 100vw, cover; // need to move this to an @supports block
  }
}

.hp-tiles {
  padding: 0 15px;
}

@media screen and (min-width: 576px) {
  /* autoprefixer grid: autoplace */
  .hp-tiles {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-template-rows: repeat(6, minmax(200px, 1fr));
  }
}

@media screen and (min-width: 993px) {
  /* autoprefixer grid: autoplace */
  .hp-tiles {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    grid-template-rows: repeat(4, minmax(200px, 1fr));
    grid-gap: 15px;
  }
}

@media screen and (min-width: 1200px) {
  /* autoprefixer grid: autoplace */
  .hp-tiles {
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    grid-template-rows: repeat(3, minmax(200px, 1fr));
  }
}

.hp-tile {
  background: #f7f7f7;
}

.homepage-separator {
    margin-top: 15px;
    margin-bottom: 30px;
    height: 3px;
    background: linear-gradient(to right, white, #22536d, white);
}

.homepage-blue-bar {
    margin: 30px 0;
    font-family: $font-family-sans;
    font-weight: bold;
    font-size: 1.4em;
    background: #116eb7;
    letter-spacing: 0.07em;
    padding: 8px;
    a {
        color: white;
        &:hover, &:focus, &:active {
            text-decoration: none;
            background-color: lighten($teal, 5%);
        }
        
    }
}

div.homepage-box {
    display: grid;
    grid-gap: 0px;
    grid-column-gap: 0px;
    grid-template-columns: 50% 50%;
    margin: 30px auto 20px auto;
    max-width: 900px;
    @media screen and (max-width: 650px) {
        grid-template-columns: 100%;
    }
}



div.homepage-box-text-a {
    background-color: $homepage-box-bg-a;
}
div.homepage-box-text-b {
    background-color: $homepage-box-bg-b;
}
div.homepage-box-text-c {
    background-color: $homepage-box-bg-c;
}
div.homepage-box-text-d {
    background-color: $homepage-box-bg-d;
}


.homepage-box-text h3 {
    font-family: $font-family-sans;
    font-size: 2em;
    margin-top: 35px;
    color: $v-yellow;
}

.homepage-box-text p {
    color: $dt-menu-bg;
    font-family: $font-family-sans;
    font-size: 1.5em;
    line-height: 1.3;    
    margin: 30px;
}

.homepage-tell-me-more-container {
    margin: 25px;
}
.homepage-tell-me-more a {
    background-color: white;
    font-family: $font-family-sans;
    font-size: 1.2em;
    color: $tile-b;
    font-weight: bold;
    border-radius: 2px;
    padding: 0 9px;
    box-shadow: 0 0 0 5px white,
                0 0 0 6px black,
                0 0 0 10px white;
    &:hover, &:focus, &:active {
        text-decoration: none;
        color: darken($tile-b, 5%);
    }
}

.homepage-box-text {
    heigth: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
